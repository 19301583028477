import React, { useEffect, useState } from "react";
import { Typography, AppBar, Toolbar, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./style.css";
import CryptoJS from "crypto-js";
import { Header } from "./components/Header";

function md5Hash(input) {
  return CryptoJS.MD5(input).toString();
}

const credentials = await fetch("/login.json")
.then((response) => response.json())
.then((jsonData) => jsonData.users)

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("isLoggedIn") === "true") {
      navigate("/dashboard");
    }
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if entered email and password match any in credentials
    const isValidEmail = credentials.some((cred) => cred.email === email);
    let isValidPassword = false;
    let isValidUser = false;

    if (!email || !password) {
      setError("Both fields are required.");
      return;
    }
    if (isValidEmail) {
      isValidPassword = credentials.some(
        (cred) => cred.email === email && cred.password === password
      );
      if (!isValidPassword) {
        setError("Wrong Password.");
        return;
      } else isValidUser = true;
    }

    if (!isValidUser) {
      setError("Invalid email or password.");
      return;
    }

    setError(""); // Clear errors if login is successful
    // alert("Login successful!");
    localStorage.setItem("isLoggedIn", "true");
    navigate("/dashboard");
  };

  const updateEmail = (e) => {
    setEmail(e.currentTarget.value);
    setError("");
  };

  const updatePassword = (e) => {
    setPassword(md5Hash(e.currentTarget.value));
    setError("");
  };

  const onEnter = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  return (
    <div className="LoginPage" style={{ backgroundColor: "#F0FAF3" }}>
      <div className="BodyContainer">
        <div className="LoginBody">
          <div className="LoginCard">
            <Typography
              className="signintext"
              variant="h5"
              sx={{ marginTop: "30px" }}
            >
              Sign In
            </Typography>
            <Typography
              className="paragraph"
              variant="p"
              sx={{ marginTop: "10px", color: "#666666" }}
            >
              Welcome back! Please enter your details
            </Typography>
            <div className="InputFieldDiv">
              <div className="inputfields">
                <Typography>Email</Typography>
                <input
                  className="input"
                  type="email"
                  placeholder="Enter your mail"
                  onChange={(e) => updateEmail(e)}
                />
              </div>
              <div className="inputfields">
                <Typography>Password</Typography>
                <input
                  className={`${
                    error === "Wrong Password." ? "wrongInput" : "input"
                  }`}
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter your password"
                  onChange={(e) => updatePassword(e)}
                  onKeyDown={(e) => {
                    onEnter(e);
                  }}
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="showHidePassword"
                >
                  {showPassword ? (
                    <Box
                      component="img"
                      src={require("./HidePassword.png")} // Placeholder, replace with actual logo
                      sx={{
                        width: "15px",
                        height: "15px",
                      }}
                    />
                  ) : (
                    <Box
                      component="img"
                      src={require("./ShowPassword.png")} // Placeholder, replace with actual logo
                      sx={{
                        width: "15px",
                        height: "15px",
                      }}
                    />
                  )}
                </button>
                <div
                  className={`${
                    error !== "" ? "wrongPassword" : "forgotPassword"
                  }`}
                >
                  {error !== "" && (
                    <Typography sx={{ color: "#7F1519", fontSize: "12px" }}>
                      {error}
                    </Typography>
                  )}
                  <a href="/">Forgot Password?</a>
                </div>
              </div>
            </div>
            <button className="SignInBtn" onClick={handleSubmit}>
              Sign In
            </button>
          </div>
        </div>
        <div style={{ width: "50%" }}>
          <Box
            component="img"
            src={require("./LoginImage.png")} // Placeholder, replace with actual logo
            sx={{
              width: "100%",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;