import React from "react";
import { Box, Typography, Button, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="md" sx={{ pt: 8, textAlign: "center" }}>
      <Box>
        <Typography variant="h1" sx={{ fontWeight: "bold", color: "primary.main", mb: 2 }}>
          404
        </Typography>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Oops! The page you're looking for doesn't exist.
        </Typography>
        <Typography variant="body1" color="text.secondary" sx={{ mb: 4 }}>
          It might have been moved or deleted. Let’s get you back on track.
        </Typography>
        <Button variant="contained" color="primary" onClick={() => navigate("/")}>
          Go to Home
        </Button>
      </Box>
    </Container>
  );
};

export default NotFoundPage;
