import React, { useState, useEffect } from "react";
import { Building, Users, AlertTriangle, ChevronDown, ChevronRight } from 'lucide-react';
import { MetricsCard } from '../components/MetricsCard';
import { TeamCard } from '../components/TeamCard';
import { ClientHealthChart } from '../components/ClientHealthChart';
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
    const [clientInfo, setClientInfo] = useState([]);
    const [metrics, setMetrics] = useState({});
    const [lastModified, setLastModified] = useState("");
    const [expandedClients, setExpandedClients] = useState(new Set());
    const [expandedBUs, setExpandedBUs] = useState(new Set());
    const [activeTab, setActiveTab] = useState('risk');
    const navigate = useNavigate();

    useEffect(() => {
      fetch("/data.json")
        .then((response) => response.json())
        .then((jsonData) => {
            setClientInfo(jsonData.data);
            setMetrics(jsonData.metrics);
            setLastModified(jsonData.lastModified);
        });
    }, []);
    
    const handleTeamClick = (clientName, buName, teamName) => {
      navigate(`/clients/${clientName}/${buName}/${encodeURIComponent(teamName)}`)
    };

    const getFilteredTeams = () => {
      const threeMonthsAgo = new Date();
      threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
  
      const allTeams = clientInfo.flatMap(client =>
        client.businessUnits.flatMap(bu => bu.teams.map(team => ({...team, clientName: client.clientName, buName: bu.buName})))
      );
  
      switch (activeTab) {
        case 'risk':
          return allTeams.filter(team => team.performanceIndex.overallHealth === 'At Risk' || team.performanceIndex.overallHealth === 'Critical').sort((a, b) => b.performanceIndex.overallHealth.localeCompare(a.performanceIndex.overallHealth));
        case 'new':
          return allTeams.filter(team => new Date(team.teamStartDate) >= threeMonthsAgo).sort((a, b) =>  new Date(b.teamStartDate) -  new Date(a.teamStartDate));
        default:
          return [];
      }
    };

    const toggleClient = (clientName) => {
      const newExpanded = new Set(expandedClients);
      if (newExpanded.has(clientName)) {
        newExpanded.delete(clientName);
      } else {
        newExpanded.add(clientName);
      }
      setExpandedClients(newExpanded);
    };

    const toggleBU = (buName) => {
      const newExpanded = new Set(expandedBUs);
      if (newExpanded.has(buName)) {
        newExpanded.delete(buName);
      } else {
        newExpanded.add(buName);
      }
      setExpandedBUs(newExpanded);
    };

    const renderHierarchicalView = () => (
      <div className="space-y-4">
        {clientInfo.map(client => (
          <div key={client.clientName} className="bg-white rounded-lg shadow-sm border border-gray-200">
            <button
              className="w-full px-4 py-3 flex items-center justify-between text-left hover:bg-gray-50"
              onClick={() => toggleClient(client.clientName)}
            >
              <div className="flex items-center">
                {expandedClients.has(client.clientName) ? (
                  <ChevronDown className="w-5 h-5 text-gray-400 mr-2" />
                ) : (
                  <ChevronRight className="w-5 h-5 text-gray-400 mr-2" />
                )}
                <img
                  src={`/images/${client.clientName}.png`}
                  alt={`${client.clientName} logo`}
                  className="w-[7rem] h-12 rounded-lg object-contain mr-3"
                />
                {/* <span className="font-medium text-gray-900">{client.clientName}</span> */}
              </div>
              <span className="text-sm text-gray-500">
                {client.businessUnits.reduce((acc, bu) => acc + bu.teams.length, 0)} Teams
              </span>
            </button>
  
            {expandedClients.has(client.clientName) && (
              <div className="pl-8 pr-4 pb-4">
                {client.businessUnits.map(bu => (
                  <div key={bu.buName} className="mt-2">
                    <button
                      className="w-full px-4 py-2 flex items-center justify-between text-left hover:bg-gray-50 rounded"
                      onClick={() => toggleBU(bu.buName)}
                    >
                      <div className="flex items-center">
                        {expandedBUs.has(bu.buName) ? (
                          <ChevronDown className="w-4 h-4 text-gray-400 mr-2" />
                        ) : (
                          <ChevronRight className="w-4 h-4 text-gray-400 mr-2" />
                        )}
                        <span className="text-sm font-medium text-gray-700">{bu.buName}</span>
                      </div>
                      <div className="flex items-center space-x-4">
                        {bu.teamsAtRisk > 0 && (
                          <span className="text-sm text-red-600">At Risk: {bu.teamsAtRisk}</span>
                        )}
                        <span className="text-sm text-gray-500">Total: {bu.teams.length}</span>
                      </div>
                    </button>
  
                    {expandedBUs.has(bu.buName) && (
                      <div className="pl-8 pr-4 mt-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
                        {bu.teams.map(team => (
                          <TeamCard key={team.teamName} team={{...team, clientName: client.clientName, buName: bu.buName }} onClick={handleTeamClick} />
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    );

    return (
            <div className="min-h-screen pt-8">
                <div className="max-w-7xl mx-auto px-4 py-8">
                    <div className="flex items-center justify-between mb-8">
                    <h1 className="text-3xl font-bold text-[#800000]">Team Dashboard</h1>
                    <div className="flex items-center space-x-2">
                        <span className="text-sm text-gray-500 font-semibold">Last updated: {lastModified}</span>
                    </div>
                    </div>

                    <div className="flex flex-col sm:flex-row">
                    <MetricsCard metrics={metrics} switchActiveTab={ (tab) => setActiveTab(tab)}/>

                    <ClientHealthChart clients={clientInfo} />
                    </div>

                    <div className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden">
                      <div className="border-b border-gray-200">
                          <nav className="flex -mb-px">
                          <button
                              className={`px-6 py-4 text-sm font-medium flex items-center space-x-2 ${
                              activeTab === 'risk'
                                  ? 'border-b-2 border-red-500 text-red-600'
                                  : 'text-gray-500 hover:text-gray-700'
                              }`}
                              onClick={() => setActiveTab('risk')}
                          >
                              <AlertTriangle className="w-5 h-5" />
                              <span>At Risk Teams</span>
                          </button>
                          <button
                              className={`px-6 py-4 text-sm font-medium flex items-center space-x-2 ${
                              activeTab === 'new'
                                  ? 'border-b-2 border-blue-500 text-blue-600'
                                  : 'text-gray-500 hover:text-gray-700'
                              }`}
                              onClick={() => setActiveTab('new')}
                          >
                              <Users className="w-5 h-5" />
                              <span>New Teams</span>
                          </button>
                          <button
                              className={`px-6 py-4 text-sm font-medium flex items-center space-x-2 ${
                              activeTab === 'all'
                                  ? 'border-b-2 border-gray-500 text-gray-600'
                                  : 'text-gray-500 hover:text-gray-700'
                              }`}
                              onClick={() => setActiveTab('all')}
                          >
                              <Building className="w-5 h-5" />
                              <span>All Teams</span>
                          </button>
                          </nav>
                      </div>

                      <div className="p-6">
                      {activeTab === 'all' ? (
                          renderHierarchicalView()
                          ) : (
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                              {getFilteredTeams().map(team => (
                                  <TeamCard
                                    team={team}
                                    key={team.teamName}
                                    onClick={handleTeamClick}
                                  />
                                ))} 
                            </div>
                          )}
                      </div>
                    </div>
                </div>
            </div>
            
    );
}

export default Dashboard;