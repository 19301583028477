export function camelToTitle(str) {
    // 1) Replace underscores with a space
    let cleaned = str.replace(/_/g, ' ');
  
    // 2) Trim whitespace
    cleaned = cleaned.trim();
  
    // 3) Collapse multiple spaces into one
    cleaned = cleaned.replace(/\s+/g, ' ');
  
    // 4) Split on space so we can handle each chunk
    const words = cleaned.split(' ');
  
    // We'll process each chunk to handle CamelCase or ALLCAPS
    const processedWords = words.map((word) => {
      // If word is ALL CAPS (e.g., "ABC"), convert it to lowercase first
      if (/^[A-Z]+$/.test(word)) {
        word = word.toLowerCase();
      }
      // Insert a space before any uppercase letter that follows a lowercase or digit
      // e.g., "autoBots" => "auto Bots", "AutoBots" => "Auto Bots"
      word = word.replace(/([a-z\d])([A-Z])/g, '$1 $2');
  
      // Now split that chunk on space (in case there are multiple uppercase letters) 
      // and Title Case each sub-chunk
      let subChunks = word.split(' ').map((sub) => {
        return sub.charAt(0).toUpperCase() + sub.slice(1).toLowerCase();
      });
  
      // Rejoin sub-chunks with space
      return subChunks.join(' ');
    });
  
    // 5) Rejoin all processed chunks with a space
    return processedWords.join(' ');
  }