import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Typography,
  Card,
  CardContent,
  CardActionArea,
  CardMedia,
  Grid,
  CircularProgress
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const ClientSelection = ({ clientKeys, data }) => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="lg" sx={{ pt: 6, boxSizing: 'unset' }}>
      {/* Page Heading */}
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
          <Typography
            variant="h4"
            sx={{
              flexGrow: 1,
              textAlign: "center",
              fontWeight: "bold",
              color: "#800000"
            }}
          >
            Select Your Client
          </Typography>
          <Button variant="contained" color="primary" sx={{ marginLeft: 'auto' }} onClick={() => navigate('/dashboard')}>
            Dashboard
          </Button>
      </Box>

      <Grid container spacing={3} justifyContent="center">
        {clientKeys.map((clientKey) => {
          const client = data.find(client => client.clientName === clientKey);
          const groups = client.businessUnits;
          const teamsCount = groups.map(bu => bu.teams.length).reduce(
            (sum, teamSize) => sum + teamSize, 0
          );

          const imageSrc = `/images/${clientKey}.png`;

          return (
            <Grid item xs={12} sm={6} md={4} lg={3} key={clientKey}>
              <Card
                sx={{
                  height: "100%",
                  borderRadius: 2,
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  "&:hover": {
                    boxShadow: "0 6px 12px rgba(0,0,0,0.2)"
                  }
                }}
              >
                <CardActionArea
                  onClick={() => navigate(`/client/${clientKey}`)}
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                  }}
                >
                  {/* Client Image */}
                  <CardMedia
                    component="img"
                    image={imageSrc}
                    alt={`${clientKey} logo`}
                    sx={{
                      width: "auto",
                      height: 120,
                      width: 200,
                      mt: 2,
                      objectFit: "contain"
                    }}
                  />
                  <CardContent sx={{ textAlign: "center", width: "100%" }}>
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: "bold",
                        mb: 2,
                        color: "#800000", 
                        textTransform: "capitalize"
                      }}
                    >
                      {clientKey}
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1, color: "#555" }}>
                      Teams: {teamsCount}
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1, color: "#555" }}>
                      Business Units: {groups.length}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};

const App = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch("/data.json")
      .then((response) => response.json())
      .then((jsonData) => setData(jsonData.data));
  }, []);

  if (!data) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh"
        }}
      >
        <CircularProgress color="primary" />
      </Box>
    );
  }

  const clientKeys = data.map(client => client.clientName);

  return (
    <Box
      sx={{
        minHeight: "100vh",
        pb: 9,
      }}
    >
      <ClientSelection clientKeys={clientKeys} data={data} />
    </Box>
  );
};

export default App;
