import React from "react";
import { Typography, Box } from "@mui/material";

const UnauthorizedPage = () => {
  return (
    <>
      <div>
        <Typography
          sx={{
            fontSize: "32px",
            fontWeight: "500",
            textAlign: "center",
            marginTop: "50px",
            marginBottom: "35px",
          }}
        >
          Error: Unauthorized Access
        </Typography>
        <div className="ErrorCard">
          <Box
            component="img"
            src={require("./ErrorUnauthorized.png")} // Placeholder, replace with actual logo
            sx={{
              width: "35px",
              height: "35px",
              marginBottom: "25px",
              marginTop: "15px",
            }}
          />
          <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
            Oops
          </Typography>
          <Typography
            sx={{ fontSize: "16px", fontWeight: "500", color: "#666666" }}
          >
            You do not have the necessary permissions to view this page.
          </Typography>
          <div className="BackBtnDiv">
            <button
              className="backbutton"
              onClick={() => {
                window.history.back();
              }}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UnauthorizedPage;