import { ArrowRight, AlertTriangle, CheckCircle, XCircle } from 'lucide-react';

const getHealthIcon = (health) => {
  switch (health) {
    case 'Healthy':
      return <CheckCircle className="w-5 h-5 text-green-500" />;
    case 'At Risk':
      return <AlertTriangle className="w-5 h-5 text-yellow-500" />;
    case 'Critical':
      return <XCircle className="w-5 h-5 text-red-500" />;
  }
};

const getHealthColor = (health) => {
  switch (health) {
    case 'Healthy':
      return 'bg-green-50 text-green-700';
    case 'At Risk':
      return 'bg-yellow-50 text-yellow-700';
    case 'Critical':
      return 'bg-red-50 text-red-700';
    default:
      return 'text-gray-600'
  }
};

const HealthIndicator = ({ label, status }) => (
  <div className="flex items-center justify-between text-sm">
    <span className="text-gray-600">{label}:</span>
    <div className="flex items-center">
      {getHealthIcon(status)}
      <span className={`ml-1 ${getHealthColor(status)}`}>
        {status}
      </span>
    </div>
  </div>
);

export const TeamCard = ({ team, onClick }) => {
  return (
    <div 
      className={`p-4 rounded-lg border ${getHealthColor(team.performanceIndex.overallHealth)} cursor-pointer transition-all hover:shadow-md`}
      onClick={() => onClick(team.clientName, team.buName, team.teamName)}
    >
      <div className="flex items-center justify-between mb-2">
        <h3 className="text-lg font-semibold">{team.teamName}</h3>
        {getHealthIcon(team.performanceIndex.overallHealth)}
      </div>
      <p className="text-sm text-gray-600 mb-2">Client: {team.clientName}</p>
      <p className="text-sm text-gray-600 mb-2">Lead: {team.lead}</p>      
      <p className="text-sm text-gray-600 mb-3">Started: {new Date(team.teamStartDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</p>
      
      <div className="space-y-2 mb-3 border-t pt-2">
        <HealthIndicator label="Delivery" status={team.performanceIndex.deliveryHealth} />
        <HealthIndicator label="Team" status={team.performanceIndex.teamHealth} />
        <HealthIndicator label="Relationship" status={team.performanceIndex.relationshipHealth} />
      </div>

      <div className="flex items-center mt-3 text-sm font-medium">
        View Details
        <ArrowRight className="w-4 h-4 ml-1" />
      </div>
    </div>
  );
};