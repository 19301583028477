import React, { useState, useEffect } from 'react';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts';

const parseDate = (dateStr) => {
  const [day, month, year] = dateStr.split('/');
  return new Date(`20${year}`, month - 1, day);
};

const groupDataByTeamAndOutcome = (data) => {
  return data.reduce((acc, item) => {
    const { Team, Outcome } = item;

    if (!acc[Team]) {
      acc[Team] = {};
    }

    if (!acc[Team][Outcome]) {
      acc[Team][Outcome] = {
        data: [],
      };
    }

    acc[Team][Outcome].data.push(item);

    return acc;
  }, {});
};

const processGroupedData = (data) => {
  const groupedData = groupDataByTeamAndOutcome(data);

  Object.keys(groupedData).forEach((team) => {
    Object.keys(groupedData[team]).forEach((outcome) => {
      const group = groupedData[team][outcome];

      group.data.sort((a, b) => {
        const dateA = parseDate(a.Date);
        const dateB = parseDate(b.Date);
        return dateA - dateB;
      });
    });
  });

  return groupedData;
};

const inputData2=[
  {
    "Team": "AUTOBOTS",
    "Date": "09/05/24",
    "Outcome": "CED Products functional automation \n- Reducing release cycle, increased functional coverage",
    "Planned or Unplanned": "Planned",
    "Phase": "Start",
    "Progress Information (Comments)": "Accelerated the Release cycle is scaled up and more aligned.",
    "percent_completed": 0
  },
  {
    "Team": "AUTOBOTS",
    "Date": "15/05/24",
    "Outcome": "CED Products functional automation \n- Reducing release cycle, increased functional coverage",
    "Planned or Unplanned": "Planned",
    "Phase": "Ideation",
    "Progress Information (Comments)": "",
    "percent_completed": 10
  },
  {
    "Team": "AUTOBOTS",
    "Date": "21/05/24",
    "Outcome": "CED Products functional automation \n- Reducing release cycle, increased functional coverage",
    "Planned or Unplanned": "Planned",
    "Phase": "shaping",
    "Progress Information (Comments)": "",
    "percent_completed": 20
  },
  {
    "Team": "AUTOBOTS",
    "Date": "30/05/24",
    "Outcome": "CED Products functional automation \n- Reducing release cycle, increased functional coverage",
    "Planned or Unplanned": "Planned",
    "Phase": "product solutioning",
    "Progress Information (Comments)": "",
    "percent_completed": 30
  },
  {
    "Team": "AUTOBOTS",
    "Date": "14/06/24",
    "Outcome": "CED Products functional automation \n- Reducing release cycle, increased functional coverage",
    "Planned or Unplanned": "Planned",
    "Phase": "planning",
    "Progress Information (Comments)": "",
    "percent_completed": 40
  },
  {
    "Team": "AUTOBOTS",
    "Date": "22/06/24",
    "Outcome": "CED Products functional automation \n- Reducing release cycle, increased functional coverage",
    "Planned or Unplanned": "Planned",
    "Phase": "delivery solutioning",
    "Progress Information (Comments)": "",
    "percent_completed": 52
  },
  {
    "Team": "AUTOBOTS",
    "Date": "28/06/24",
    "Outcome": "CED Products functional automation \n- Reducing release cycle, increased functional coverage",
    "Planned or Unplanned": "Planned",
    "Phase": "execution",
    "Progress Information (Comments)": "",
    "percent_completed": 72
  },
  {
    "Team": "AUTOBOTS",
    "Date": "02/07/24",
    "Outcome": "CED Products functional automation \n- Reducing release cycle, increased functional coverage",
    "Planned or Unplanned": "Planned",
    "Phase": "delivered",
    "Progress Information (Comments)": "",
    "percent_completed": 80
  },
  {
    "Team": "AUTOBOTS",
    "Date": "23/10/24",
    "Outcome": "POC - Compound assets migration to OL",
    "Planned or Unplanned": "Unplanned",
    "Phase": "Start",
    "Progress Information (Comments)": "Migrating the compound assets from CED to OL",
    "percent_completed": 0
  },
  {
    "Team": "AUTOBOTS",
    "Date": "25/10/24",
    "Outcome": "POC - Compound assets migration to OL",
    "Planned or Unplanned": "Unplanned",
    "Phase": "Ideation",
    "Progress Information (Comments)": "Idea is to download the compound assets from CMS and upload it into ABS, to convert file from zip to interactive assets by communicating with LABs API.",
    "percent_completed": 10
  },
  {
    "Team": "AUTOBOTS",
    "Date": "29/10/24",
    "Outcome": "POC - Compound assets migration to OL",
    "Planned or Unplanned": "Unplanned",
    "Phase": "shaping",
    "Progress Information (Comments)": "As a POC, downloaded a compound asset from CMS and uploaded it into ABS and made it as an interactive asset and tested this by publishing the CW where this asset is attached in OL Platform.",
    "percent_completed": 20
  },
  {
    "Team": "AUTOBOTS",
    "Date": "02/11/24",
    "Outcome": "POC - Compound assets migration to OL",
    "Planned or Unplanned": "Unplanned",
    "Phase": "product solutioning",
    "Progress Information (Comments)": "Developed a script to automate this complete process.",
    "percent_completed": 30
  },
  {
    "Team": "AUTOBOTS",
    "Date": "04/11/24",
    "Outcome": "POC - Compound assets migration to OL",
    "Planned or Unplanned": "Unplanned",
    "Phase": "planning",
    "Progress Information (Comments)": "Looked for Asset IDs of compound asset and the respective APIs to make this assets interactive in Bento.",
    "percent_completed": 40
  },
  {
    "Team": "AUTOBOTS",
    "Date": "05/11/24",
    "Outcome": "POC - Compound assets migration to OL",
    "Planned or Unplanned": "Unplanned",
    "Phase": "delivery solutioning",
    "Progress Information (Comments)": "Integrated the script with OCR Migration script.",
    "percent_completed": 52
  },
  {
    "Team": "AUTOBOTS",
    "Date": "05/11/24",
    "Outcome": "POC - Compound assets migration to OL",
    "Planned or Unplanned": "Unplanned",
    "Phase": "execution",
    "Progress Information (Comments)": "As a part of OCR Migration script, this script will take care of migrating the compound assets from CMS to Bento as interactive assets.",
    "percent_completed": 72
  },
  {
    "Team": "AUTOBOTS",
    "Date": "06/11/24",
    "Outcome": "POC - Compound assets migration to OL",
    "Planned or Unplanned": "Unplanned",
    "Phase": "delivered",
    "Progress Information (Comments)": "As a part of POC, able to migrate unit 1 for one of the OCR Course, which includes entire unit content along with compound assets.",
    "percent_completed": 80
  },
  {
    "Team": "AUTOBOTS",
    "Date": "07/10/24",
    "Outcome": "POC - Assessment Bank review - Increasing Editorial efficiency",
    "Planned or Unplanned": "Unplanned",
    "Phase": "Start",
    "Progress Information (Comments)": "Presence of Gramatical mistakes and other errors in the assesment banks and authors faced challenges to identify and rectify this issues.",
    "percent_completed": 0
  },
  {
    "Team": "AUTOBOTS",
    "Date": "07/10/24",
    "Outcome": "POC - Assessment Bank review - Increasing Editorial efficiency",
    "Planned or Unplanned": "Unplanned",
    "Phase": "Ideation",
    "Progress Information (Comments)": "",
    "percent_completed": 10
  },
  {
    "Team": "AUTOBOTS",
    "Date": "07/10/24",
    "Outcome": "POC - Assessment Bank review - Increasing Editorial efficiency",
    "Planned or Unplanned": "Unplanned",
    "Phase": "shaping",
    "Progress Information (Comments)": "",
    "percent_completed": 20
  },
  {
    "Team": "AUTOBOTS",
    "Date": "07/10/24",
    "Outcome": "POC - Assessment Bank review - Increasing Editorial efficiency",
    "Planned or Unplanned": "Unplanned",
    "Phase": "product solutioning",
    "Progress Information (Comments)": "",
    "percent_completed": 30
  },
  {
    "Team": "AUTOBOTS",
    "Date": "07/10/24",
    "Outcome": "POC - Assessment Bank review - Increasing Editorial efficiency",
    "Planned or Unplanned": "Unplanned",
    "Phase": "planning",
    "Progress Information (Comments)": "",
    "percent_completed": 40
  },
  {
    "Team": "AUTOBOTS",
    "Date": "08/10/24",
    "Outcome": "POC - Assessment Bank review - Increasing Editorial efficiency",
    "Planned or Unplanned": "Unplanned",
    "Phase": "delivery solutioning",
    "Progress Information (Comments)": "",
    "percent_completed": 52
  },
  {
    "Team": "AUTOBOTS",
    "Date": "08/10/24",
    "Outcome": "POC - Assessment Bank review - Increasing Editorial efficiency",
    "Planned or Unplanned": "Unplanned",
    "Phase": "execution",
    "Progress Information (Comments)": "",
    "percent_completed": 72
  },
  {
    "Team": "AUTOBOTS",
    "Date": "08/10/24",
    "Outcome": "POC - Assessment Bank review - Increasing Editorial efficiency",
    "Planned or Unplanned": "Unplanned",
    "Phase": "delivered",
    "Progress Information (Comments)": "",
    "percent_completed": 80
  },
  {
    "Team": "AUTOBOTS",
    "Date": "07/11/24",
    "Outcome": "Implementation of CAT Tracker - Increasing Authoring Efficiency",
    "Planned or Unplanned": "Unplanned",
    "Phase": "Start",
    "Progress Information (Comments)": "Digital AD can use this script to migrate courses to Bento using automation. Previously, vendors had to manually construct courses, which was time-consuming.",
    "percent_completed": 0
  },
  {
    "Team": "AUTOBOTS",
    "Date": "14/11/24",
    "Outcome": "Implementation of CAT Tracker - Increasing Authoring Efficiency",
    "Planned or Unplanned": "Unplanned",
    "Phase": "Ideation",
    "Progress Information (Comments)": "Identified spreadsheet/Smartsheet structure and mapped “Unit” “Lesson/Section” based on row values for structured migration.",
    "percent_completed": 10
  },
  {
    "Team": "AUTOBOTS",
    "Date": "21/11/24",
    "Outcome": "Implementation of CAT Tracker - Increasing Authoring Efficiency",
    "Planned or Unplanned": "Unplanned",
    "Phase": "shaping",
    "Progress Information (Comments)": "Converted the sheet to JSON format. Course IDs are provided via CLI based on course structure. If a lesson is missing, the entire node is deleted and recreated; if a node is missing, it is added as new.",
    "percent_completed": 20
  },
  {
    "Team": "AUTOBOTS",
    "Date": "28/11/24",
    "Outcome": "Implementation of CAT Tracker - Increasing Authoring Efficiency",
    "Planned or Unplanned": "Unplanned",
    "Phase": "product solutioning",
    "Progress Information (Comments)": "Designed the script in TypeScript with CLI commands for migration. The script takes excel-name and course-id as arguments, with Excel files stored in Downloads/excel.",
    "percent_completed": 30
  },
  {
    "Team": "AUTOBOTS",
    "Date": "05/12/24",
    "Outcome": "Implementation of CAT Tracker - Increasing Authoring Efficiency",
    "Planned or Unplanned": "Unplanned",
    "Phase": "planning",
    "Progress Information (Comments)": "Outlined execution roadmap, technical requirements, and dependencies.",
    "percent_completed": 40
  },
  {
    "Team": "AUTOBOTS",
    "Date": "12/12/24",
    "Outcome": "Implementation of CAT Tracker - Increasing Authoring Efficiency",
    "Planned or Unplanned": "Unplanned",
    "Phase": "delivery solutioning",
    "Progress Information (Comments)": "Developed a structured approach for seamless data integration and validation. Created a repository and relevant Confluence page for execution steps.",
    "percent_completed": 52
  },
  {
    "Team": "AUTOBOTS",
    "Date": "19/12/24",
    "Outcome": "Implementation of CAT Tracker - Increasing Authoring Efficiency",
    "Planned or Unplanned": "Unplanned",
    "Phase": "execution",
    "Progress Information (Comments)": "Implemented, tested, and optimized the migration script. Verified that all courses from the sheet are successfully migrated to Bento.",
    "percent_completed": 72
  },
  {
    "Team": "AUTOBOTS",
    "Date": "20/12/24",
    "Outcome": "Implementation of CAT Tracker - Increasing Authoring Efficiency",
    "Planned or Unplanned": "Unplanned",
    "Phase": "delivered",
    "Progress Information (Comments)": "Successfully validated data migration, ensuring efficiency, accuracy, and automation of the process.",
    "percent_completed": 80
  },
  {
    "Team": "AUTOBOTS",
    "Date": "05/12/24",
    "Outcome": "RW Content Migration POC - Revenue Generation",
    "Planned or Unplanned": "Planned",
    "Phase": "Start",
    "Progress Information (Comments)": "OCR Products where about to sunset as they were not accessible, so there was a need to migrate them to OL.",
    "percent_completed": 0
  },
  {
    "Team": "AUTOBOTS",
    "Date": "09/12/24",
    "Outcome": "RW Content Migration POC - Revenue Generation",
    "Planned or Unplanned": "Planned",
    "Phase": "Ideation",
    "Progress Information (Comments)": "The idea is to migrate the course content from CED to OL using either APIs or database.",
    "percent_completed": 10
  },
  {
    "Team": "AUTOBOTS",
    "Date": "12/12/24",
    "Outcome": "RW Content Migration POC - Revenue Generation",
    "Planned or Unplanned": "Planned",
    "Phase": "shaping",
    "Progress Information (Comments)": "Finalised with the API approach and identified the API involved for both source (CMS) and destination (Bento) and created a test courseware in Bento using APIs.",
    "percent_completed": 20
  },
  {
    "Team": "AUTOBOTS",
    "Date": "16/12/24",
    "Outcome": "RW Content Migration POC - Revenue Generation",
    "Planned or Unplanned": "Planned",
    "Phase": "product solutioning",
    "Progress Information (Comments)": "Created a Framework using TS to fetch the details of CMS APIs and stored them in JSONs and feed those JSONs to Bento API to create a course structure.",
    "percent_completed": 30
  },
  {
    "Team": "AUTOBOTS",
    "Date": "17/12/24",
    "Outcome": "RW Content Migration POC - Revenue Generation",
    "Planned or Unplanned": "Planned",
    "Phase": "planning",
    "Progress Information (Comments)": "Outlining the steps and resources needed to develop and implement the solution.",
    "percent_completed": 40
  },
  {
    "Team": "AUTOBOTS",
    "Date": "18/12/24",
    "Outcome": "RW Content Migration POC - Revenue Generation",
    "Planned or Unplanned": "Planned",
    "Phase": "delivery solutioning",
    "Progress Information (Comments)": "Identified the course content needs to be migrated along with the basic assets (Docs, Images, PDFs etc.).",
    "percent_completed": 52
  },
  {
    "Team": "AUTOBOTS",
    "Date": "19/12/24",
    "Outcome": "RW Content Migration POC - Revenue Generation",
    "Planned or Unplanned": "Planned",
    "Phase": "execution",
    "Progress Information (Comments)": "Handed over the solution to execution team, post successful POC. To scale it up for multiple units.",
    "percent_completed": 72
  },
  {
    "Team": "AUTOBOTS",
    "Date": "20/12/24",
    "Outcome": "RW Content Migration POC - Revenue Generation",
    "Planned or Unplanned": "Planned",
    "Phase": "delivered",
    "Progress Information (Comments)": "As a part of POC, able to migrate unit 1 for one of the OCR Course, which includes entire unit content along with basic assets.",
    "percent_completed": 80
  }
]

const groupedData = processGroupedData(inputData2);

const generateHillCurve = () => {
  const points = [];
  // const peakX = 40;
  const peakY = 100;
  const totalPoints = 80;

  for (let i = 0; i <= totalPoints; i++) {
    const x = i;
    const y = peakY * Math.sin((Math.PI * x) / totalPoints);
    points.push({ x, y });
  }

  return points;
};

const hillData = generateHillCurve();
const MAX_SCROLL = 40;

const findClosestYValue = (xValue) => {
  const closest = hillData.find((point) => point.x === Math.round(xValue)) || hillData[0];
  return closest.y;
};

const MovingGraph = () => {
  const [scrollValue, setScrollValue] = useState(0);
  const [matchingPoints, setMatchingPoints] = useState([]);

  const getOutcomeColor = (() => {
    const colors = ['#2196F3', '#4CAF50', '#FFC107', '#9C27B0', '#FF5722'];
    const outcomeColors = new Map();
    let colorIndex = 0;

    return (outcome) => {
      if (!outcomeColors.has(outcome)) {
        outcomeColors.set(outcome, colors[colorIndex % colors.length]);
        colorIndex++;
      }
      return outcomeColors.get(outcome);
    };
  })();

  const handleScroll = (event) => {
    setScrollValue(Number(event.target.value));
  };

  useEffect(() => {
    const startOfYear = new Date('2024-01-01');
    const scrollDays = (scrollValue / MAX_SCROLL) * 365;
    const currentDate = new Date(startOfYear);
    currentDate.setDate(currentDate.getDate() + scrollDays);

    const latestPointsByOutcome = new Map();

    Object.keys(groupedData).forEach(team => {
      Object.keys(groupedData[team]).forEach(outcome => {
        const outcomeData = groupedData[team][outcome];
        let latestPoint = null;

        outcomeData.data.forEach(item => {
          const itemDate = parseDate(item.Date);
          
          if (itemDate <= currentDate) {
            const yCoord = findClosestYValue(item.percent_completed);
            const point = {
              x: item.percent_completed,
              y: yCoord,
              date: item.Date,
              phase: item.Phase,
              outcome: outcome,
              progressInfo: item['Progress Information']
            };
            
            // Update latest point for this outcome
            if (!latestPoint || parseDate(point.date) > parseDate(latestPoint.date)) {
              latestPoint = point;
            }
          }
        });

        // Only add the latest point for each outcome
        if (latestPoint) {
          latestPointsByOutcome.set(outcome, latestPoint);
        }
      });
    });

    // Convert Map to array
    setMatchingPoints(Array.from(latestPointsByOutcome.values()));
  }, [scrollValue]);

  const startOfYear = new Date('2024-01-01');
  const scrollDays = (scrollValue / MAX_SCROLL) * 365;
  const currentDate = new Date(startOfYear);
  currentDate.setDate(currentDate.getDate() + scrollDays);

  const formattedDate = currentDate.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });

  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <h2>Project Progress Visualization</h2>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '80%',
        margin: 'auto',
      }}>
        <div style={{ position: 'relative', width: '100%' }}>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="x"
                type="number"
                domain={[0, 80]}
                ticks={[0, 20, 40, 60, 80]}
              />
              <YAxis domain={[0, 100]} width={50} />
              <Tooltip />
              <Line
                type="monotone"
                data={hillData}
                dataKey="y"
                stroke="#000"
                strokeWidth={2}
                dot={(props) => {
                  const { cx, cy, payload } = props;
                  
                  const matchingPoint = matchingPoints.find(
                    point => Math.abs(point.x - payload.x) < 0.5
                  );

                  if (matchingPoint) {
                    const color = getOutcomeColor(matchingPoint.outcome);
                    return (
                      <circle
                        cx={cx}
                        cy={cy}
                        r={6}
                        fill={color}
                        stroke="black"
                        strokeWidth={2}
                      />
                    );
                  }
                  return null;
                }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>

        <input
          type="range"
          min="0"
          max={MAX_SCROLL}
          step="0.1"
          value={scrollValue}
          onChange={handleScroll}
          style={{
            width: 'calc(100% - 50px)',
            marginLeft: '50px',
            marginTop: '20px',
          }}
        />
        
        <div style={{ marginTop: '20px', textAlign: 'left', width: '100%' }}>
          <p style={{ fontSize: '16px', fontWeight: 'bold' }}>
            Current Date: {formattedDate}
          </p>
          <p style={{ fontSize: '16px', marginBottom: '10px' }}>
            Active Points: {matchingPoints.length}
          </p>
          
          {matchingPoints.map((point, index) => (
            <div 
              key={index} 
              style={{ 
                marginBottom: '10px', 
                padding: '10px', 
                backgroundColor: `${getOutcomeColor(point.outcome)}20`,
                borderRadius: '5px',
                borderLeft: `4px solid ${getOutcomeColor(point.outcome)}`
              }}
            >
              <div style={{ fontWeight: 'bold' }}>Phase: {point.phase}</div>
              <div>Position: ({point.x.toFixed(1)}, {point.y.toFixed(1)})</div>
              <div>Date: {point.date}</div>
              <div style={{ fontSize: '14px', color: '#666' }}>{point.progressInfo}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MovingGraph;