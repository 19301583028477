import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    Chip,
    Container,
    Divider,
    Typography,
    TextField,
    InputAdornment
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate, useParams } from "react-router-dom";
import Grid from '@mui/material/Grid2';
import { camelToTitle } from "../common/utils";

const Logo = ({client}) => (
    <Box
        component="img"
        src={`/images/${client}.png`}
        alt={`${client} Logo`}
        sx={{ width: 40, height: "auto" }}
    />
);

const ClientPage = () => {
    const { clientKey } = useParams();
    const navigate = useNavigate();
    const [clientData, setClientData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        fetch("/data.json")
            .then((res) => res.json())
            .then((json) => {
                const allClients = json.data || [];
                const buInfo = allClients.find(client => client.clientName === clientKey).businessUnits || [];
                setClientData(buInfo);
                setFilteredData(buInfo);
            });
    }, [clientKey]);

    // Filter data when search term changes
    useEffect(() => {
        if (!searchTerm.trim()) {
            setFilteredData(clientData);
            return;
        }

        const lowerSearchTerm = searchTerm.toLowerCase();
        const filteredGroups = [];

        // For each group in clientData
        clientData.forEach((rawGroupKey) => {
            // Convert the group key to its displayed text, e.g. "auto_bots" -> "Auto Bots"
            const displayGroupKey = rawGroupKey.buName;
            const matchingTeams = {};

            // Check if the GROUP itself matches the search
            const groupMatches = displayGroupKey.toLowerCase().includes(lowerSearchTerm);

            if (groupMatches) {
                // If the group name itself matches, include all teams in this group
                filteredGroups.push(rawGroupKey);
                return;
            }

            // Otherwise, check each team
            rawGroupKey.teams.forEach((rawTeamKey, teamValue) => {
                // Convert team key to displayed text
                const displayTeamKey = rawTeamKey.teamName;
                // Check if the displayed team name includes the search
                if (displayTeamKey.toLowerCase().includes(lowerSearchTerm)) {
                    matchingTeams[displayTeamKey] = teamValue;
                }
            });

            // If any teams matched, include just those teams for this group
            if (Object.keys(matchingTeams).length > 0) {
                filteredGroups.push(rawGroupKey);
            }
        });

        setFilteredData(filteredGroups);
    }, [searchTerm, clientData]);

    // Highlight matching text in the displayed label
    const highlightMatch = (displayText, searchTerm) => {
        if (!searchTerm.trim()) return displayText;

        const lowerText = displayText.toLowerCase();
        const lowerSearchTerm = searchTerm.toLowerCase();

        if (!lowerText.includes(lowerSearchTerm)) return displayText;

        const startIndex = lowerText.indexOf(lowerSearchTerm);
        const endIndex = startIndex + searchTerm.length;

        return (
            <>
                {displayText.substring(0, startIndex)}
                <span style={{ backgroundColor: '#ffff00', fontWeight: 'bold' }}>
                    {displayText.substring(startIndex, endIndex)}
                </span>
                {displayText.substring(endIndex)}
            </>
        );
    };

    return (
        <Container maxWidth="lg" sx={{ pt: 4, pb: 4 }}>
            {/* Back Button */}
            <Button
                variant="outlined"
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate(-1)}
                sx={{ mb: 3 }}
            >
                Back
            </Button>

            <Typography variant="h4" sx={{ textAlign: "center", mb: 4, color: "primary.main" }}>
                Please Select Your Team
            </Typography>

            {/* Search Box */}
            <Box sx={{ mb: 4 }}>
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Search by Business Units or Team Name..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        maxWidth: 600,
                        mx: 'auto',
                        display: 'block',
                        '& .MuiOutlinedInput-root': {
                            borderRadius: 2,
                        }
                    }}
                />
            </Box>

            {Object.keys(filteredData).length === 0 && searchTerm && (
                <Typography variant="body1" sx={{ textAlign: "center", my: 4, color: "text.secondary" }}>
                    No matching groups or teams found.
                </Typography>
            )}

            {/* Render Groups & Teams */}
            <Grid container spacing={2} justifyContent="center" className="leading-[1.1] [&_*]:box-content">
                {filteredData.map((rawGroupKey) => {
                    // The user sees the group name in Title format
                    const displayGroupKey = rawGroupKey.buName;

                    return (
                        <Grid key={displayGroupKey}>
                            <Card
                                sx={{
                                    width: {
                                        xs: "300px",
                                        sm: "450px",
                                        md: "500px"
                                    },
                                    px: 3,
                                    borderRadius: 3,
                                    boxShadow: 3,
                                    height: "100%"
                                }}
                            >
                                <CardContent>
                                    <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 2 }}>
                                        <Logo client={clientKey}/>
                                        <Typography variant="h6" sx={{ fontWeight: "bold", flexGrow: 1 }}>
                                            {highlightMatch(displayGroupKey, searchTerm)}
                                        </Typography>
                                    </Box>

                                    <Divider sx={{ mb: 2 }} />

                                    {/* <Typography variant="subtitle1" sx={{ textAlign: "left", fontWeight: "bold", mb: 1 }}>
                                        Teams
                                    </Typography> */}

                                    <Box
                                        sx={{
                                            display: "grid",
                                            gridTemplateColumns: {
                                                xs: "repeat(1, 1fr)",
                                                sm: "repeat(2, 1fr)",
                                                md: "repeat(3, 1fr)"
                                            },
                                            gap: 1.5,
                                            margin: 2,
                                            maxHeight: 250,
                                            overflowY: "auto",
                                            p: 1,
                                        }}
                                    >
                                        {rawGroupKey.teams.map((rawTeamKey) => {
                                            // Convert team key to displayed text
                                            const displayTeamKey = rawTeamKey.teamName;

                                            return (
                                                <Chip
                                                    key={displayTeamKey}
                                                    label={
                                                        <Box component="span" sx={{ whiteSpace: "normal" }}>
                                                            {highlightMatch(displayTeamKey, searchTerm)}
                                                        </Box>
                                                    }
                                                    clickable
                                                    variant="outlined"
                                                    onClick={() =>
                                                        navigate(`/clients/${clientKey}/${displayGroupKey}/${encodeURIComponent(displayTeamKey)}`)
                                                    }
                                                    sx={{
                                                        fontSize: 14,
                                                        px: 2,
                                                        py: 1,
                                                        borderRadius: 2,
                                                        bgcolor: "background.paper",
                                                        color: "#800000",
                                                        borderColor: "#800000",
                                                        height: "auto",
                                                        "& .MuiChip-label": {
                                                            whiteSpace: "normal",
                                                            overflow: "visible",
                                                            textOverflow: "clip",
                                                            display: "block"
                                                        }
                                                    }}
                                                />
                                            );
                                        })}
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    );
                })}
            </Grid>
        </Container>
    );
};

export default ClientPage;
